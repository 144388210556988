<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">입찰참여내역</h2>
      </div>
      <div class="body_section">
        <BidListFilterBar
          ref="filterBar"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :statusList="statusList"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />
        <h3 class="screen_out">입찰 참여 내역 목록</h3>
        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 108px" />
            <!-- 진행 상태 -->
            <col style="width: 92px" />
            <!-- 등록일 -->
            <col style="width: 152px" />
            <!-- 공고 번호 -->
            <col style="width: 220px" />
            <!-- 공고 명 -->
            <col style="width: 172px" />
            <!-- 분류 -->
            <col style="width: 150px" />
            <!-- 구매품목 -->
            <col style="width: 92px" />
            <!-- 입찰 시작일 -->
            <col style="width: 92px" />
            <!-- 입찰 종료일 -->
            <col style="width: 162px" />
            <!-- 구매 담당자 -->
          </template>
          <template v-slot:tr>
            <th>진행 상태</th>
            <th>공고 등록일</th>
            <th>입찰 공고번호</th>
            <th>입찰 공고명</th>
            <th>분류</th>
            <th>구매 품목</th>
            <th>입찰 시작일</th>
            <th>입찰 마감일</th>
            <th>구매 담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <BidListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="10" class="td_empty">입찰 업체가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import BidListFilterBar from "@/components/shared/bid/list/BidListFilterBar";
import TableHead from "@/components/shared/TableHead";
import ListPage from "@/components/shared/ListPage";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import BidListLine from "@/components/user/bid/list/BidListLine";

import BidListMixin from "@/mixins/bid/list/BidListMixin";

export default {
  components: {
    PageWithLayout,
    BidListFilterBar,
    TableHead,
    SelectBoxPageRange,
    BidListLine,
    ListPage,
  },
  mixins: [BidListMixin],
  data() {
    return {
      KEYWORD_DATAS: [
        { id: "postCid", code: "postCid", desc: "입찰 공고번호" },
        { id: "title", code: "title", desc: "입찰 공고명" },
        { id: "name", code: "name", desc: "구매 담당자" },
      ],
      defaultKeywordSelectedId: "postCid",
    };
  },
};
</script>
